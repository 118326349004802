<script lang="ts">
  import type { SeoBundle } from "$types/custom";

  import GA4 from "$lib/components/GoogleAnalytics.svelte";
  import { page } from "$app/stores";

  export let seo:SeoBundle;

  // Alert about non-optional SEO members
  if (!seo.title)       console.warn(`SEO data on '${ $page.url.pathname }' does not include a title`);
  if (!seo.description) console.warn(`SEO data on '${ $page.url.pathname }' does not include a description`);
  if (!seo.image)       console.warn(`SEO data on '${ $page.url.pathname }' does not include a graph image`);

  // Optional members
  //if (!seo.keywords)    console.warn("SEO data does not include keywords");

  const ga_id = import.meta.env.VITE_GA4_MEASUREMENT_ID;
  const ENVIRONMENT = process.env.ENVIRONMENT;

  const PEEK = false;
</script>


<svelte:head>
  <title>{ seo.title }</title>
  <meta name="description" content={ seo.description } />

  {#if seo.image && seo.image.url}
    <meta name="og:image" content={ seo.image.url } />
  {/if}

  {#if seo.keywords}
    <meta name="keywords" content={ seo.keywords } />
  {/if}
  {#if ENVIRONMENT !== "production"}
    <meta name="robots" content="noindex,nofollow" />
  {/if}
</svelte:head>

<GA4 id={ga_id} />

{#if PEEK}
  <pre style="position:fixed;right:0;bottom:0;z-index:99999;background:black">
    SEO: {JSON.stringify(seo, null, 2)}
  </pre>
{/if}
